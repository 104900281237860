import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/owenbean/ems/ems_web/src/components/ApiDocumentationLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Update Rescue`}</h1>
    <h2>{`PUT `}<inlineCode parentName="h2">{`/v1/ems/rescue`}</inlineCode></h2>
    <p>{`Update a rescue with all values provided. All fields must be provided.`}</p>
    <h3>{`Request`}</h3>
    <p>{`Headers`}</p>
    <pre><code parentName="pre" {...{}}>{`"Authorization": "Bearer <Token>"
`}</code></pre>
    <h4>{`Body`}</h4>
    <pre><code parentName="pre" {...{}}>{`{
    "id": [string] The ID of the record,
    "call_date": [string] Call date time in ISO 8602,
    "enroute_date": [string] Enroute date time in ISO 8602,
    "scene_date": [string] Scene date time in ISO 8602,
    "transportation": [string] Transportation date time in ISO 8602,
    "destination_date" [string] Destination date time in ISO 8602,
    "mileage": [number] Amount of mileage with rescue,
    "notes": [string] Extra notes to the rescue,
    "truck_id": [string] The ID of truck record,
    "truck_id": [string | NULL] The ID of truck record.
}
`}</code></pre>
    <h3>{`Response `}<inlineCode parentName="h3">{`201`}</inlineCode></h3>
    <p>{`The rescue has been successfully updated. Response is the newly updated truck data.`}</p>
    <h4>{`Body`}</h4>
    <pre><code parentName="pre" {...{}}>{`{
    "id": [string] The ID of the record,
    "call_date": [string] Call date time in ISO 8602,
    "enroute_date": [string] Enroute date time in ISO 8602,
    "scene_date": [string] Scene date time in ISO 8602,
    "transportation": [string] Transportation date time in ISO 8602,
    "destination_date" [string] Destination date time in ISO 8602,
    "mileage": [number] Amount of mileage with rescue,
    "notes": [string] Extra notes to the rescue,
    "truck_id": [string] The ID of truck record,
    "truck_id": [string | NULL] The ID of truck record.
}
`}</code></pre>
    <h3>{`Response `}<inlineCode parentName="h3">{`401`}</inlineCode></h3>
    <p>{`Authorization issue.`}</p>
    <h4>{`Body`}</h4>
    <pre><code parentName="pre" {...{}}>{`{
    "Error": "Unauthorized",
    "message": [string] Authorization issue
}
`}</code></pre>
    <h4>{`Messages`}</h4>
    <ul>
      <li parentName="ul">{`You are not authorized to access this resource`}
        <blockquote parentName="li">
          <p parentName="blockquote">{`The token from Firebase is invalid`}</p>
        </blockquote>
      </li>
      <li parentName="ul">{`Authorization header is missing`}
        <blockquote parentName="li">
          <p parentName="blockquote">{`There is no "Authorization" in the header request.`}</p>
        </blockquote>
      </li>
      <li parentName="ul">{`Invalid authorization header format`}
        <blockquote parentName="li">
          <p parentName="blockquote">{`The "Authorization" header does not have "Bearer Token" format.`}</p>
        </blockquote>
      </li>
    </ul>
    <h3>{`Response `}<inlineCode parentName="h3">{`500`}</inlineCode></h3>
    <p>{`Internal error with the server.`}</p>
    <h4>{`Body`}</h4>
    <pre><code parentName="pre" {...{}}>{`{
    "error": "Internal Server Error",
    "message": "Internal Server Error"
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      